<template>
  <div class="card mb-5 mb-xxl-8">
    <div class="card-body pt-0 pe-0 pb-0">
    <div class="d-flex overflow-auto h-55px"> 
      <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                 :to="'/interview/userInterview/' + Number(id) + '/detail'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("modals.interview.userInterviewDetail.tabs.one") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/interview/userInterview/' + Number(id) + '/responses'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("modals.interview.userInterviewDetail.tabs.two") }}
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
    </div>
  </div>
  </div>
         
  <router-view />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { COMPONENT_NAME } from "@/presentation/constants/Components";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";

export default defineComponent({
  name: COMPONENT_NAME.INVOICES, // değiştirilecek
  props: ['id'],
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();

    const id = route.params["id"];

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(20);
    const totalItems = ref(0);
    const pageCount = ref(1);

    //   const getAnimalDiseasesList = (page) => {
    //     isLoading.value = true;

    //     animalDiseaseList.value = [];

    //     if (page < 1) {
    //       page = 1;
    //     }
    //     if (page > pageCount.value) {
    //       page = pageCount.value;
    //     }
    //     currentPage.value = page;

    //     const animalDiseaseListModel: AnimalDiseaseListModel = {
    //       disease: {
    //         animalId: Number(animalId),
    //       },
    //       page: page,
    //       pageSize: pageSize.value,
    //     };

    //     animalController
    //       .animalDiseaseList(animalDiseaseListModel)
    //       .then((response) => {
    //         if (response.isSuccess) {
    //           response.getValue().forEach((animalDisease) => {
    //             totalItems.value = animalDisease.pagination.total;
    //             currentPage.value = animalDisease.pagination.page;
    //             pageCount.value = animalDisease.pagination.pageCount;
    //             animalDiseaseList.value.push(animalDisease);
    //           });
    //         } else {
    //           swalNotification.error(
    //             response.error.cause.cause +
    //               t(SWAL_MESSAGES.ERROR_CODE) +
    //               response.error.code +
    //               response.error.cause.code,
    //             t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
    //           );
    //         }
    //         isLoading.value = false;
    //       })
    //       .catch((e) => {
    //         swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
    //       });
    //   };

    //   const treatAnimal = (id) => {
    //     const animalDiseaseUpdateModel: AnimalDiseaseUpdateModel = {
    //       id: id,
    //       endDate: new Date(),
    //     };

    //     Swal.fire({
    //       title: t(SWAL_MESSAGES.ARE_YOU_SURE),
    //       text: t(SWAL_MESSAGES.SURE_TREAT_ANIMAL),
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: t("common.button.yes"),
    //       cancelButtonText: t("common.button.cancel"),
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         animalController
    //           .updateAnimalDisease(animalDiseaseUpdateModel)
    //           .then((response) => {
    //             if (response.isSuccess) {
    //               store.dispatch("newDisease", store.getters.newDisease + 1 );
    //               Swal.fire({
    //                 title: t(SWAL_MESSAGES.SUCCESS),
    //                 text: t(SWAL_MESSAGES.SUCCESS_UPDATE_ANIMAL_DISEASE),
    //                 icon: "success",
    //               }).then(() => {
    //               });
    //             } else {
    //               swalNotification.error(
    //                 response.error.cause.cause +
    //                   t(SWAL_MESSAGES.ERROR_CODE) +
    //                   response.error.code +
    //                   response.error.cause.code,
    //                 t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
    //               );
    //             }
    //           })
    //           .catch((e) => {
    //             swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
    //           });
    //       }
    //     });

    //   }

    onMounted(async () => {
      setCurrentPageTitle(t("modals.interview.title"));
      // getAnimalDiseasesList(1);
    });

    return {
      PAGE_TITLE_PATH,
      id,
      // animalDiseaseList,
      // treatAnimal,
      isLoading,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
